import React, { useState } from 'react'; 

const ContactForm = () => {
  const [timelyValue, setTimelyValue] = useState('');
  const [formData, setFormData] = useState({
    first_name: '',
    email: '',
    phone: '',
    country: '',
    state: '',  // Renamed from 'city' to 'state' to match API
    enquiry: '',
    reference: 'UAE Website',  // Fixed value
    certification: '',
    training: '',
    market_form: 'Direct',  // Fixed value
    comment: '',
    subject: 'mail subject'  // Fixed value
});

  const [message, setMessage] = useState('');

  const changeDropdown = (e) => {
    const value = e.target.value;
    setTimelyValue(value);
    
    setFormData(prevState => ({
        ...prevState,
        enquiry: value,
        training: value === 'Training' ? 'ISO 9001:2015 (QMS)' : '', 
        certification: value === 'Certification' ? 'ISO 9001:2015 (QMS)' : '' 
    }));
};


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
};

const handleSubmit = async (e) => {
  e.preventDefault();

  const apiUrl = "https://testing.bscic.com/api/websiteLeads/";

  try {
      const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
              'authorization': "746accd7-e928-4c51-939d-97f15ba2ac12",
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (result.status) {
          setMessage('Form submitted successfully!');

          // Reset form fields
          setFormData({
              first_name: '',
              email: '',
              phone: '',
              country: '',
              state: '',
              enquiry: '',
              reference: 'UAE Website',
              certification: '',
              training: '',
              market_form: 'Direct',
              comment: '',
              subject: 'mail subject'
          });

          setTimelyValue(''); // Reset the dropdown value
      } else {
          setMessage(result.message || 'Submission failed.');
      }
  } catch (error) {
      setMessage('An error occurred. Please try again.');
  }
};



  return (
    <div style={styles.container}>
      <h2>Contact Us</h2>
      {message && <p style={{ color: 'green' }}>{message}</p>}
      <form id="fs-frm" name="survey-form" acceptCharset="utf-8" onSubmit={handleSubmit} style={styles.form}>
        <fieldset style={styles.fieldset}>
          <label style={styles.label} htmlFor="full-name">Full Name</label>
          <input type="text" name="first_name" id="full-first_name" placeholder="First and Last" required style={styles.input} onChange={handleChange} />

          <label style={styles.label} htmlFor="email-address">Email Address</label>
          <input type="email" name="email" id="email-address" placeholder="Email Address" required style={styles.input} onChange={handleChange} />

          <label style={styles.label} htmlFor="phone-no">Phone No</label>
          <input type="tel" name="phone" id="phone-no" placeholder="Phone No" required style={styles.input} onChange={handleChange} />

          <label style={styles.label} htmlFor="country">Country</label>
          <input type="text" name="country" id="country" placeholder="Country" required style={styles.input} onChange={handleChange} />

          <label style={styles.label} htmlFor="city">City/Province</label>
          <input type="text" name="state" id="state" placeholder="City/Province" required style={styles.input} onChange={handleChange} />

          <select 
              name="enquiry" 
              id="timely" 
              value={formData.enquiry}  // Bind value to formData.enquiry
              required 
              onChange={changeDropdown} 
              style={styles.input}
          >
              <option value="" disabled>Choose</option>
              <option value="Training">Training</option>
              <option value="Certification">Certification</option>
            </select>

          {timelyValue === 'Training' && (
            <div style={styles.optionContainer}>
              <label style={styles.label} htmlFor="training">Courses</label>
              <select name="training" id="training" style={styles.input} onChange={handleChange}>
                <option value="ISO 9001:2015 (QMS)">ISO 9001:2015 (QMS)</option>
                <option value="ISO 27001 - ISMS">ISO 27001 - ISMS</option>
                <option value="ISO 14001 - EMS">ISO 14001 - EMS</option>
                <option value="ISO 45001 - OHSMS">ISO 45001 - OHSMS</option>
                <option value="ISO 13485:2016 - MDQMS">ISO 13485:2016 (MDQMS)</option>
                <option value="ISO 22000:2018 (FSMS)">ISO 22000:2018 (FSMS)</option>
                <option value="ISO 22301:2019 (BCMS)">ISO 22301:2019 (BCMS)</option>
                <option value="Other Trainings">Other Training</option>
              </select>
            </div>
          )}

          {timelyValue === 'Certification' && (
            <div style={styles.optionContainer}>
              <label style={styles.label} htmlFor="certification">Standard</label>
              <select name="certification" id="certification" style={styles.input} onChange={handleChange}>
                <option value="ISO 9001:2015 (QMS)">ISO 9001:2015 (QMS)</option>
                <option value="ISO 14001:2015 (EMS)">ISO 14001:2015 (EMS)</option>
                <option value="ISO 45001:2018 (OHSMS)">ISO 45001:2018 (OHSMS)</option>
                <option value="ISO 22000:2018 (FSMS)">ISO 22000:2018 (FSMS)</option>
                <option value="ISO/IEC 27001:2013 (ISMS)">ISO/IEC 27001:2013 (ISMS)</option>
                <option value="ISO 22301:2019 (BCMS)">ISO 22301:2019 (BCMS)</option>
                <option value="ISO/IEC 20000-1:2018 (IT-SMS)">ISO/IEC 20000-1:2018 (IT-SMS)</option>
                <option value="ISO 13485:2016 - MDQMS">ISO 13485:2016 (MDQMS)</option>
                <option value="HACCP">HACCP</option>
                <option value="Other Certification">Other Certification</option>
                <option value="IMS">IMS</option>
              </select>
            </div>
          )}

          <label style={styles.label} htmlFor="message">Comments</label>
          <textarea rows="3" name="comment" id="message" placeholder="." required style={styles.input} onChange={handleChange} />

        </fieldset>
        <input type="submit" value="Send Responses" style={styles.submitButton} />
      </form>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'left',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f5f5f5',
    margin: 0,
    padding: 20,
  },
  form: {
    maxWidth: 600,
    margin: '20px auto',
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 8,
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
  },
  fieldset: {
    border: '1px solid #ddd',
    padding: 10,
    marginBottom: 20,
  },
  label: {
    display: 'block',
    marginBottom: 8,
    color: '#333',
  },
  input: {
    width: '100%',
    padding: 8,
    marginBottom: 16,
    border: '1px solid #ccc',
    borderRadius: 4,
    color: 'black'
  },
  optionContainer: {
    marginTop: 10,
  },
  submitButton: {
    backgroundColor: '#4caf50',
    color: '#fff',
    padding: '10px 15px',
    border: 'none',
    borderRadius: 4,
    cursor: 'pointer',
    fontSize: 16,
    marginTop: 10,
  },
};

export default ContactForm; 
